<template>
  <div class="homepage-wrapper">
    <section class="banner" >
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-md-10 col-lg-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <h2 >
            Snel, leuk en efficiënt werken in onze software pakketten
             
            </h2>

            <v-btn color="red"  style="color:white" to="MakelaarsSoftware">  Makelaarssoftware</v-btn> <v-btn color="red"  style="color:white" to="Wordpress-plug-ins"> Wordpress-plug-ins</v-btn>
       
          </div>
        </div>
      </div>
    </section>

    <section class="why alternate-section">
      <div class="main-section-content">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              order="1"
              order-md="0"
             xx
            >
              <h2>Over Ascosoftware</h2>
              <p class="font-weight-medium pr-5">
                  Over ons bedrijf Asco Software levert kassa software, Wordpress en Makelaardij CRM. Wij zijn
              gespecialiseerd in het ontwikkelen van software bedrijven. Wij leveren onze innovatieve
              software in België en Nederland. Ons bedrijf is opgericht in 2019
              waarbij wij gestart zijn met het ontwikkelen van modules voor
              kassasystemen. Wij zijn al snel overgestapt naar het bouwen van
              complete oplossingen. Waarom? Omdat hier een grote behoefte
              naar is bij MKB ondernemers. Wij vinden dat een software betaalbaar en innovatief moet zijn.
              </p>
              <v-btn
              style="color:white"
                color="red"
                elevation="9"
                large
                raised
                rounded
                @click="goToAbout()"
              >
                Meer over Ascosoftware
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" order="0" order-md="1">
              <v-img
                class="align-end why-section-image"
                height="300px"
                src="@/assets/about.png"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="diagonal diagonal-bottom-right"></div>
    </section>

    <section class="places">
      <h2 class="text-center mb-5">Onze diensten</h2>

      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/modules/wordpess.jpg"
                >
                  <v-card-title style="color:red">Wordpress-Bol.com</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="error"
                        class="text-decoration-none"
                        to="contact"
                        >Meer informatie</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          
         
         
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/kassa.png"
                >
                  <v-card-title style="color:red ">Kassa software</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="error"
                        class="text-decoration-none"
                      to="contact"
                        >Meer informatie</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/crm.jpg"
                >
                  <v-card-title style="color:red;">Makelaars CRM</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="error"
                        class="text-decoration-none"
                     to="contact"
                        >Meer informatie</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="workspaces alternate-section">
      <div class="diagonal diagonal-top-left"></div>
     
                     
                    
                 
               
     
    
     
      
    

 

    </section>

    <section class="booking text-center mx-auto">
      <h2 class="mb-5">Begin vandaag nog!</h2>
      <p class="mb-5">
       Neem vandaag contact met ons op voor een demo
      </p>
      <div>
        <router-link to="contact" class="text-decoration-none"
          ><v-btn color="error"> Demo aanvragen</v-btn></router-link
        >
      </div>
    </section>

     
   
  </div>
</template>

<script>
  import { BLink } from 'bootstrap-vue';

  export default {
    data: () => {
      return {
      
        erasingSpeed: 100,
        newTextDelay: 2000,
        typeArrayIndex: 0,
        charIndex: 0,

      };
    },
    components: {
      BLink,
    },
  };
</script>

<style>
  .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
    max-height: inherit !important;
  }
</style>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .homepage-wrapper {
    --primary-color: #F9F9F9;
    --alternate-color: white;
    font-family: 'Poppins', sans-serif;
  }
  section.alternate-section .main-section-content {
    background-color: var(--primary-color);
    color: var(--alternate-color);
  }

  section.why .main-section-content {
    padding: 70px 0px 35px;
    color:black;
    font-family: Poppins, sans-serif;
  }
  section.workspaces {
    margin-top: 50px;
       color:black;
  
  }
  section.workspaces .main-section-content {
    padding: 35px 0px 70px;
  }

  .why-section-image {
    border-radius: 10px 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
  .diagonal {
    height: 100px;
  }
  .diagonal-bottom-right {
    background-image: linear-gradient(
      to bottom left,
      var(--primary-color) 49%,
      transparent 50%
    );
  }
  .diagonal-top-left {
    background-image: linear-gradient(
      to top right,
      var(--primary-color) 49%,
      transparent 50%
    );
  }
  .places {
    padding-top: 60px;
  }

  .booking {
    padding: 50px 20px;
    width: 100%;
    max-width: 800px;
       color:black;
  
  }

  .partners {
    padding: 50px 0px;
    background-color: #e7e7e5;
    text-align: center;
  }
  .wrapper {
    display: flex;
  }



  header {
    position: absolute;
    z-index: 10;
    width: 100%;
  }
  .navbar {
    padding: 20px 0px;
  }

  .navbar-brand {
    font-weight: 800;
    text-transform: uppercase;
  }

  .banner {
    background-image: url('~@/assets/header.png');
    background-size: cover;
    width: 100%;
    padding: 100px 0px 250px;
  }

  .banner h2 {
    margin: 0;
    padding: 0;
   font-family:sans-serif;
   font-size: 54px;
   font-weight:900;
   line-height:64px
  }

  .banner p {
    margin: 1em 0 0;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.5em;
  }

  @media (max-width: 768px) {
    .banner {
      background-image: url('~@/assets/header.png');
      width: 100%;
      padding: 100px 0px 250px;
    }
  }

  h2 span.typed-text {
    color: orange;
  }

  span.cursor {
    display: inline-block;
    margin-left: 3px;
    width: 4px;
    background-color: black;
    animation: cursorBlink 1s infinite;
  }
  span.cursor.typing {
    animation: none;
  }

  @keyframes cursorBlink {
    49% {
      background-color: black;
    }
    50% {
      background-color: transparent;
    }
    99% {
      background-color: transparent;
    }
  }
  .link {
    color: rgba(79, 89, 98);
    text-decoration: none;
    transition: color 300ms ease-in-out;
  }
  .link:hover {
    color: rgba(79, 89, 98, 0.5);
  }
  .card-image-link {
    overflow: hidden;
    cursor: pointer;
  }
  .card-image {
    transition: 300ms;
  }
  .card-image:hover {
    transform: scale(1.2);
  }
</style>
