<template>
  <HomePage />
</template>

<script>
import HomePage from "@/components/HomePage";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    HomePage,
  },
  metaInfo: {
    title: "Welkom bij Ascosoftware | Software bedrijf Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
     meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Ascosoftware Nederland voor alle kantoorplekken",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>